<template>
  <div>
    <!--    <b-button-->
    <!--      variant="primary"-->
    <!--      size="sm"-->
    <!--      class="mb-1"-->
    <!--      @click="onSaveSettings"-->
    <!--    >-->
    <!--      {{ $t('Save') }}-->
    <!--    </b-button>-->

    <!-- INTEGRATIONS - Autenti -->
    <b-card>
      <template #header>
        <h4 class="d-flex align-items-center">
          Autenti

          <b-form-checkbox
            v-model="autentiEnabled"
            class="ml-1"
            checked="true"
            name="check-button"
            variant="primary"
            switch
            inline
            @change="updateAutenti"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </h4>
      </template>

      <b-row>
        <b-col
          lg="12"
        >
          <b-alert
            variant="primary"
            show
            class="p-1"
          >
            <div>
              {{ $t('SignDocumentsOnline') }}
            </div>

            <div class="mt-50 d-flex align-items-center">
              <b-button
                size="sm"
                target="_blank"
                class="mr-25"
                variant="primary"
                href="https://meetings.hubspot.com/lukasz-zakrzewski"
              >
                {{ $t('AutentiMeeting') }}
              </b-button>

              <div class="d-flex justify-content-center">
                <b-button
                  size="sm"
                  target="_blank"
                  class="mr-25"
                  variant="flat-primary"
                  href="https://account.autenti.com/register?refId=4bsystems"
                >
                  {{ $t('CreateFreeAccount') }}
                </b-button>
              </div>
            </div>
          </b-alert>
          <transition name="fade">
            <b-alert v-if="autentiEnabled"
                     show
                     :variant="connections.length ? 'primary' : 'secondary'"
            >
              <div class="alert-body d-flex flex-column justify-content-center align-items-center">
                <div class="d-flex flex-row justify-content-center align-items-center">
                  <b-avatar :variant="connections.length ? 'primary' : 'secondary'"
                            class="mr-50"
                  >
                    <feather-icon :icon="connections.length ? 'UnlockIcon' : 'LockIcon'" />
                  </b-avatar>
                  {{ connections.length && connections[0].active ? $t('ConnectionActive') : $t('NoConnectionCreate') }}
                </div>

                <div v-if="!connections.length"
                     class="w-25 mt-50"
                     style="min-width: 250px"
                >
                  <b-form-group :label="$t('ClientId')">
                    <b-form-input v-model="connectionClientId" />
                  </b-form-group>

                  <b-form-group :label="$t('APIKey')">
                    <b-form-input v-model="connectionAccessToken"
                                  type="password"
                    />
                  </b-form-group>
                </div>

                <b-button v-if="!connections.length"
                          variant="secondary"
                          :disabled="!connectionAccessToken || !connectionClientId"
                          @click="loginProcess(false)"
                >
                  <feather-icon icon="KeyIcon"
                                class="mr-25"
                  />
                  {{ $t('Authorize') }}
                </b-button>
                <b-button v-else
                          variant="outline-secondary"
                          @click="deactivateConnection(true)"
                >
                  <feather-icon icon="XIcon"
                                class="mr-25"
                  />
                  {{ $t('Deactivate') }}
                </b-button>
              </div>
            </b-alert>
          </transition>
          <!--          <b-form-group>-->
          <!--            <b-form-checkbox-->
          <!--              v-model="autentiTest"-->
          <!--              checked="true"-->
          <!--              name="check-button"-->
          <!--              variant="primary"-->
          <!--              switch-->
          <!--              inline-->
          <!--            >-->
          <!--              {{ autentiTest ? $t('TestMode') : $t('ProductionMode') }}-->
          <!--            </b-form-checkbox>-->
          <!--          </b-form-group>-->

          <!--          <b-form-group :label="$t('APIKey')">-->
          <!--            <b-form-input-->
          <!--              v-model="autentiToken"-->
          <!--              :disabled="!autentiEnabled"-->
          <!--              type="password"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--          <b-form-group :label="$t('Email')">-->
          <!--            <b-form-input-->
          <!--              v-model="autentiEmail"-->
          <!--              :disabled="!autentiEnabled"-->
          <!--              type="text"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--          <b-form-group :label="$t('contact.companyName')">-->
          <!--            <b-form-input-->
          <!--              v-model="autentiOrganizationName"-->
          <!--              :disabled="!autentiEnabled"-->
          <!--              type="text"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--          <b-form-group :label="$t('OrganizationDetails.vatId')">-->
          <!--            <b-form-input-->
          <!--              v-model="autentiOrganizationTaxNumber"-->
          <!--              :disabled="!autentiEnabled"-->
          <!--              type="text"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--          <b-form-group :label="$t('OrganizationPosition')">-->
          <!--            <b-form-input-->
          <!--              v-model="autentiOrganizationJobTitle"-->
          <!--              :disabled="!autentiEnabled"-->
          <!--              type="text"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <b-overlay
            :show="loading"
            no-wrap
            spinner-variant="primary"
          />
        </b-col>

        <b-col
          lg="12"
        >
          <div style="max-height: 400px"
               class="embed-responsive embed-responsive-16by9 h-100 rounded"
          >
            <iframe
              class="embed-responsive-item"
              src="https://www.youtube.com/embed/3HGVO3sbinc"
              allowfullscreen
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { GET_PRIVATE_SETTINGS, SAVE_SETTINGS } from '@/@constants/mutations'
import image from '@/assets/images/pages/not-authorized.svg'
import axiosIns from '@/libs/axios'

export default {
  name: 'Autenti',
  components: {
    BAlert,
  },
  data() {
    return {
      loading: false,
      autentiToken: '',
      autentiEmail: '',
      autentiEnabled: false,
      autentiTest: false,
      autentiOrganizationName: '',
      autentiOrganizationTaxNumber: '',
      autentiOrganizationJobTitle: '',
      // eslint-disable-next-line global-require
      downImg: image,
      connections: [],

      connectionAccessToken: '',
      connectionClientId: '',
    }
  },
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
    }),
  },
  async mounted() {
    if (this.$route.query?.code) {
      try {
        const response = await axiosIns.get('1/integration/autenti/connections', { params: { fields_load: this.$fields.AUTENTI_CONNECTIONS } })
        const connections = (response.data?.data?.items || [])

        if (connections.length) {
          const r = await axiosIns.patch('1/integration/autenti/connections', { code: this.$route.query.code, id: connections[0].id })
          if (!r.data?.data?.items?.length) return
        } else {
          const r = await axiosIns.post('1/integration/autenti/connections', { code: this.$route.query.code })
          if (!r.data?.data?.items?.length) return
        }

        this.code = this.$route.query.code
        window.history.replaceState({}, null, '/')

        await this.loadData()
      } catch (err) {
        console.error(err)
      }
    } else await this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      try {
        const r = await axiosIns.get('1/integration/autenti/connections', { params: { fields_load: this.$fields.AUTENTI_CONNECTIONS, isNotNull_accessToken: 1, isNotNull_refreshToken: 1 } })
        this.connections = (r.data?.data?.items || [])
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
      this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        .then(res => {
          //     this.$set(this, 'autentiToken', res.data.item?.autentiToken || '**************************')
          //     this.$set(this, 'autentiEmail', res.data.item?.autentiEmail || '')
          this.$set(this, 'autentiEnabled', res.data.item?.autentiEnabled || false)
          //     this.$set(this, 'autentiTest', res.data.item?.autentiTest || false)
          //     this.$set(this, 'autentiOrganizationName', res.data.item?.autentiOrganizationName || '')
          //     this.$set(this, 'autentiOrganizationTaxNumber', res.data.item?.autentiOrganizationTaxNumber || '')
          //     this.$set(this, 'autentiOrganizationJobTitle', res.data.item?.autentiOrganizationJobTitle || '')
          //
          //     this.loading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.loading = false
        })
    },
    updateAutenti() {
      this.loading = true
      const payload = {
        autentiEnabled: this.autentiEnabled,
      }

      this.$store.dispatch(`system/${SAVE_SETTINGS}`, { privateData: payload })
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('ChangesSaved'))
          this.loadData()
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    async deactivateConnection() {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      await axiosIns.patch('1/integration/autenti/connections', { accessToken: null, refreshToken: null, id: this.connections[0].id })
      this.showToast('success', this.$i18n.t('ChangesSaved'))

      await this.loadData()
    },
    async loginProcess() {
      const response = await axiosIns.get('1/integration/autenti/connections', { params: { fields_load: this.$fields.AUTENTI_CONNECTIONS } })
      const connections = (response.data?.data?.items || [])
      const payload = {
        autentiClientId: this.connectionClientId,
        autentiSecret: this.connectionAccessToken,
      }

      if (connections.length) {
        await axiosIns.patch('1/integration/autenti/connections', { ...payload, id: connections[0].id })
      } else {
        await axiosIns.post('1/integration/autenti/connections', { ...payload })
      }

      const { origin } = window.location
      window.location.replace(`https://autenti.saleswizardapp.com/?app=${origin}`)
    },
  },
}
</script>

<style scoped>

</style>
